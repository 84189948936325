import React, { lazy, Suspense } from 'react';
import './App.css';


const Home = lazy(() => import('./Pages/Home/Home'));

function App() {
  return (
    <div
      style={{ backgroundColor: '#0C1117' }}
    >
      <Suspense fallback={<div className="min-h-screen flex flex-col justify-center items-center">
        <div className="text-center">
          <progress className="progress w-56"></progress>
        </div>
      </div>}>
        <Home />
      </Suspense>
    </div>
  );
}

export default App;
